import axios from "../plugins/axios";

export async function getTenantById(tenantId, token) {
    try {
        const { data } = await axios({
            headers: {
                token: token
            },
            method: "GET",
            url: `/tenant/${tenantId}`
        });

        if (data && data.ok) {
            delete data.ok;
            return data;
        }
    } catch (e) {
        alert(
            "Ocorreu um erro ao processar sua solicitação. Por favor tente mais tarde."
        );
        console.log(`ERRO ${e.code} - ${e.message}`);
    }
}

export async function getTenantsWithCatalogs(token) {
    try {
        const { data } = await axios({
            headers: {
                token: token
            },
            method: "GET",
            url: `/ima/webview/tenant/tenantsWithCatalogs`
        });
        return data;
    } catch (e) {
        alert(
            "Ocorreu um erro ao processar sua solicitação. Por favor tente mais tarde."
        );
        console.log(`ERRO ${e.code} - ${e.message}`);
    }
}
export async function tenantsByAccountId(token) {
    try {
        const { data } = await axios({
            headers: {
                token: token
            },
            method: "GET",
            url: `/ima/webview/tenant/tenantsByAccountId`
        });
        return data;
    } catch (e) {
        alert(
            "Ocorreu um erro ao processar sua solicitação. Por favor tente mais tarde."
        );
        console.log(`ERRO ${e.code} - ${e.message}`);
    }
}

export async function updateTenantAddress(tenantId, address, token) {
    const { data } = await axios({
        url: "/tenant/updateTenantAddress",
        method: "PUT",
        data: {
            id: tenantId,
            addressBook: [address]
        },
        headers: {
            token: token
        }
    });

    if (!data.ok)
        throw new Error(data.message)
}

export async function updateLegalTenant(legalTenantDto, token) {
    const { data } = await axios({
        url: "/tenant/updateLegalGeneralData",
        method: "PUT",
        data: {
            ...legalTenantDto
        },
        headers: {
            token: token
        }
    });

    if (!data.ok)
        throw new Error(data.message)
}

export async function updateNaturalTenant(legalTenantDto, token) {
    const { data } = await axios({
        url: "/tenant/updateNaturalGeneralData",
        method: "PUT",
        data: {
            ...legalTenantDto
        },
        headers: {
            token: token
        }
    });

    if (!data.ok)
        throw new Error(data.message)
}