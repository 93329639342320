import Vue from 'vue';
import axios from 'axios';

// console.log(process.env)
const baseURL = process.env.VUE_APP_API_URI;

const instace = axios.create({
    baseURL,
    auth: false,
    headers: {
        'x-auth-deviceId': '000000',
        'x-auth-deviceType': 'Desktop',
        'Content-Type': 'application/json',
    }
});

Vue.axios = instace;

export default instace;