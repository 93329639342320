import Vue from 'vue'
import Vuetify from 'vuetify'
import Vuex from 'vuex'

import App from './App.vue'
import router from './router';

import actions from './store/actions'
import mutations from './store/mutations'
import state from './store/state'

import { VueMaskDirective } from 'v-mask'
import '@mdi/font/css/materialdesignicons.css'
import VueClipboard from 'vue-clipboard2'
import createPersistedState from 'vuex-persistedstate'
import "./plugins/vuetify-money.js";
// register directive v-money and component <money>
Vue.config.productionTip = false;
Vue.use(Vuetify)
Vue.use(Vuex)
Vue.directive('mask', VueMaskDirective)
Vue.use(VueClipboard)
new Vue({
    router,
    store: new Vuex.Store({
        state: state,
        actions: actions,
        mutations: mutations,
        strict: false,
        plugins: [
            createPersistedState()
        ]
    }),
    vuetify: new Vuetify(),
    render: h => h(App)
}).$mount('#app');