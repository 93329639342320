export default {
    setSignInInfo(state, payload) {
        state.signInInfo = payload;
    },
    setTenantInfo(state, payload) {
        state.tenant = payload
    },
    setPreferencesInfo(state, payload) {
        state.preferences = payload
    }
}