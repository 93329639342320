import Vue from 'vue';
import Router from 'vue-router';

// import profileComponent from './views/account/profile.vue'
// import legalAgreementComponent from './views/account/legal-agreement.vue'
// import loginComponent from './views/account/login.vue'
// import productComponent from './views/product/product.vue'
// import addressComponent from './views/account/address.vue'
// import paymentComponent from './views/payment/payment.vue'
// import cvvComponent from './views/payment/cvv.vue'
// import cartComponent from './views/cart/cart.vue'
// import product2Component from './views/product/product-2.vue'
// import receiptComponent from './views/receipt/receipt.vue'
// import detailsComponent from './views/receipt/details.vue'
// import tenantComponent from './views/tenant/tenant.vue'
// import stockComponent from './views/stock/stock.vue'
// import tenantAddressComponent from './views/tenant/tenant-address.vue'
// import checkoutEbanxComponent from './views/payment/checkout-ebanx.vue'
// import checkoutComponent from './views/payment/checkout.vue'
// import paymentOnboardingComponent from './views/payment/onboarding.vue'
// import chooseTenant from './views/tenant/choose-tenant.vue'


// lazy loading
const profileComponent = () => import(/* webpackChunkName: "profile" */        './views/account/profile.vue')
const legalAgreementComponent = () => import(/* webpackChunkName: "legalAgreement" */ './views/account/legal-agreement.vue')
const loginComponent = () => import(/* webpackChunkName: "login" */          './views/account/login.vue')
const newAccountComponent = () => import(/* webpackChunkName: "login" */          './views/account/new-account.vue')
const productComponent = () => import(/* webpackChunkName: "product" */        './views/product/product.vue')
const addressComponent = () => import(/* webpackChunkName: "address" */        './views/account/address.vue')
const paymentComponent = () => import(/* webpackChunkName: "payment" */        './views/payment/payment.vue')
const cvvComponent = () => import(/* webpackChunkName: "cvv" */            './views/payment/cvv.vue')
const cartComponent = () => import(/* webpackChunkName: "cart" */           './views/cart/cart.vue')
const product2Component = () => import(/* webpackChunkName: "product2" */       './views/product/product-2.vue')
const receiptComponent = () => import(/* webpackChunkName: "receipt" */        './views/receipt/receipt.vue')
const detailsComponent = () => import(/* webpackChunkName: "details" */        './views/receipt/details.vue')
const tenantComponent = () => import(/* webpackChunkName: "tenant" */         './views/tenant/tenant.vue')
const stockComponent = () => import(/* webpackChunkName: "stock" */          './views/stock/stock.vue')
const tenantAddressComponent = () => import(/* webpackChunkName: "tenantAddress" */  './views/tenant/tenant-address.vue')
const checkoutEbanxComponent = () => import(/* webpackChunkName: "checkoutEbanx" */  './views/payment/checkout-ebanx.vue')
const checkoutComponent = () => import(/* webpackChunkName: "checkoutJuno" */       './views/payment/checkout-juno.vue')
const checkoutUltragateComponent = () => import(/* webpackChunkName: "checkoutJuno" */       './views/payment/checkout-ultragate.vue')
const paymentOnboardingComponent = () => import(/* webpackChunkName: "paymentOnboarding" */       './views/payment/onboarding.vue')
const chooseTenant = () => import(/* webpackChunkName: "chooseTenant" */       './views/tenant/choose-tenant.vue')

Vue.use(Router);

const routes = [{
    component: profileComponent,
    name: 'profile',
    path: '/profile/:id'
},
{
    component: legalAgreementComponent,
    name: 'legal-agreement',
    path: '/legal-agreement/:agreementType'
},
{
    component: loginComponent,
    name: 'login',
    path: '/login'
},
{
    component: newAccountComponent,
    name: 'new-account',
    path: '/new-account'
},
{
    component: productComponent,
    name: 'product',
    path: '/product/:productId'
},
{
    component: addressComponent,
    name: 'address',
    path: '/address/:addressIndex'
},
{
    component: checkoutComponent,
    name: 'checkout-juno',
    path: '/checkout-juno'
},
{
    component: checkoutEbanxComponent,
    name: 'checkout-ebanx',
    path: '/checkout-ebanx'
},
{
    component: checkoutUltragateComponent,
    name: 'checkout-ultragate',
    path: '/checkout-ultragate'
},
{
    component: paymentOnboardingComponent,
    name: 'payment-onboarding',
    path: '/payment-onboarding'
},
{
    component: paymentComponent,
    name: 'payment',
    path: '/payment'
},
{
    component: cvvComponent,
    name: 'cvv',
    path: '/cvv'
},
{
    component: cartComponent,
    name: 'cart',
    path: '/cart',
},
{
    component: product2Component,
    name: 'product-2',
    path: '/product-2/:productId'
},
{
    component: receiptComponent,
    name: 'receipt',
    path: '/order/details/:orderId'
},
{
    component: detailsComponent,
    name: 'details',
    path: '/order/details'
},
{
    component: tenantComponent,
    name: 'tenant',
    path: '/tenant/:tenantId'
},
{
    component: stockComponent,
    name: 'stock',
    path: '/stock/:id'
},
{
    component: tenantAddressComponent,
    name: 'tenant-address',
    path: '/tenant/address/:tenantId'
},
{
    component: chooseTenant,
    name: 'chooseTenant',
    path: '/chooseTenant'
},
{
    component: () =>
        import( /*webpackChunkName: "upload-image" */ './views/administrator/upload-image.vue'),
    name: 'upload-image',
    path: '/upload-image'
},
{
    
    component: () =>
        import( /*webpackChunkName: "service-channel" */ './views/administrator/service-channel.vue'),
    name: 'service-channel',
    path: '/service-channel'
},
{
    
    component: () =>
        import( /*webpackChunkName: "service-channel-dev" */ './views/administrator/service-channel-dev.vue'),
    name: 'service-channel-dev',
    path: '/service-channel-dev'
},
{
    component: () =>
        import( /*webpackChunkName: "signin" */ './views/administrator/sign-in.vue'),
    name: 'signin',
    path: '/signin'
},
{
    component: () =>
        import( /*webpackChunkName: "home" */ './views/administrator/administrator.vue'),
    name: 'administrator',
    path: '/administrator',
    children: [{
        component: () =>
            import( /*webpackChunkName: "administrator-orders" */ './views/administrator/orders/orders.vue'),
        name: 'administrator-orders',
        path: 'orders'
    },
    {
        component: () =>
            import( /*webpackChunkName: "administrator-products" */ './views/administrator/products/products.vue'),
        name: 'administrator-products',
        path: 'products'
    },    
    {
        component: () =>
            import( /*webpackChunkName: "administrator-profile" */ './views/administrator/profile/profile.vue'),
        name: 'administrator-profile',
        path: 'profile'
    }, {
        component: () =>
            import( /*webpackChunkName: "administrator-address" */ './views/administrator/address/address.vue'),
        name: 'administrator-address',
        path: 'address'
    }, {
        component: () =>
            import( /*webpackChunkName: "administrator-preferences" */ './views/administrator/preferences/preferences.vue'),
        name: 'administrator-preferences',
        path: 'preferences'
    }
    ]
},
]
const router = new Router({
    base: process.env.BASE_URL,
    mode: 'history',
    routes: routes
});

router.beforeEach((to, from, next) => {
    if (to.path.indexOf('/administrator') > -1 && !localStorage.getItem('hubnai'))
        next({ name: 'signin' })

    next()
})

export default router