export default {
    signInInfo: {
        email: String,
        token: String,
        accountId: String,
        groups: Array
    },
    tenant: {
        _id: String,
        document: String,
        documentType: String,
        companyAlias: String,
        country: String,
        addressBook: Array,
        business: String,
        email: String,
        phone: Array,
        openingHours: Array,
        isDefault: Boolean,
        website: String,
        logo: Object,
        companyName: String,
        stateSubscriptions: String,
        citySubscription: String,
        dateOfBirth: Date,
        customerCode: String
    },
    preferences: {
        enableOrder: Boolean,
        canOrderAfterHours: Boolean,
        openingHours: Array
    }
}