import { getTenantById } from '../functions/tenant'

export default {
    async setSignInInfo(context, payload) {
        const signInInfo = payload.signInInfo;
        const tenantId = payload?.tenantId;
        context.commit('setSignInInfo', signInInfo);
        console.log("entrei")
        console.log(tenantId);
        if (signInInfo.tenants && signInInfo.tenants.length) {
            //Neste caso simplificado, somente estamos pegando o primeiro tenant
            const firstTenantId = tenantId ? tenantId : signInInfo.tenants[0]
            const { tenant } = await getTenantById(firstTenantId, signInInfo.token)
            context.commit('setTenantInfo', tenant)
        }
    },
    setTenantOpeningHours(context, payload) {
        const { newOpeningHours, tenant } = payload
        tenant.openingHours = newOpeningHours
        context.commit('setTenantInfo', tenant)
    },
    setPreferencesEnableOrder(context, payload) {
        const { enableOrder, preferences } = payload
        preferences.enableOrder = enableOrder
        context.commit('setPreferencesInfo', preferences)
    },
    setPreferencesCanOrderAfterHours(context, payload) {
        const { canOrderAfterHours, preferences } = payload
        preferences.canOrderAfterHours = canOrderAfterHours
        context.commit('setPreferencesInfo', preferences)
    },
    setPreferencesOpeningHours(context, payload) {
        const { openingHours, preferences } = payload
        preferences.openingHours = openingHours
        context.commit('setPreferencesInfo', preferences)
    }
}